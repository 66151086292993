














import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'moment-item-video',
})
export default class MomentItemVideo extends Vue {
  @Prop({}) private data!: any
  private isPlay = false
  private imgWidth = 0
  private imgHeight = 0
  private isFullScreen = true

  private videoPlay() {
    this.isPlay = true
    const refs: any = this.$refs.videoContent
    if (refs) {
      refs.play()
    }
  }

  private videoPause() {
    this.isPlay = false
    const refs: any = this.$refs.videoContent
    if (refs) {
      refs.pause()
    }
  }

  private clickVideo() {
    console.log('点击视频')
    if (this.isPlay) {
      this.videoPause()
    } else {
      this.videoPlay()
    }
  }
}
