



















































import {
  WechatModule
} from "@/store/modules/wechat";
import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";
import MomentItemLink from "./link.vue"
import MomentItemImg from "./img.vue"
import MomentItemVideo from './video.vue'
import BottomInput from '@/components/global/bottom-input.vue'
import {
  parseTime
} from '@/utils/func'
import {
  api
} from '@/api'

@Component({
  name: 'moment-item',
  components: {
    MomentItemLink,
    MomentItemImg,
    MomentItemVideo,
    BottomInput
  },
  filters: {
    transTime: function (val: any) {
      return parseTime(val)
    }
  }
})
export default class MomentItem extends Vue {
  @Prop({}) private sns!: any
  @Prop({}) private type!: number // 0 设备朋友圈 1 好友朋友圈
  private defaultAva: string = 'https://oss.guojiangmedia.com/wxbackend/default-avatar.png';
  private isShow = false
  private inputPlaceholder = ''
  private replyConent: any = {
    nickname: '',
    wxstrid: '',
    comment_svr_id: 0,
    comment: ''
  }

  private get deviceInfo(): any {
    return WechatModule.currentDeviceInfo ? JSON.parse(WechatModule.currentDeviceInfo) : null
  }

  private get friendInfo(): any {
    return WechatModule.currentFriInfo ? JSON.parse(WechatModule.currentFriInfo) : null
  }

  private get wxStrId(): string {
    return WechatModule.wxStrId
  }

  private get wxId(): number {
    return WechatModule.wxId
  }

  /**
   * @func 跳转到用户聊天详情
   * @param wxstrid 用户字符串wxid
   */
  private toFriendDetail(wxstrid: string) {
    if (!wxstrid) return;
    if (wxstrid === this.wxStrId) return
  }

  /**
   * @func 回复内容
   * @param nickname
   * @param wxstrid
   * @param svr_id
   */
  private openReply(nickname: string, wxstrid: string, svr_id: number) {
    if (wxstrid === this.wxStrId || !svr_id) return
    this.inputPlaceholder = '回复' + nickname
    this.replyConent = {
      nickname: nickname,
      wxstrid: wxstrid,
      comment_svr_id: svr_id
    }
    this.isShow = true
    const refs: any = this.$refs.commentInput
    if (refs) {
      refs.onFocus()
    }
  }

  /**
   * @func 点赞朋友圈
   */
  private async likeComment() {
    if (this.sns.like && this.sns.like.length) {
      for (let i = 0; i < this.sns.like.length; i++) {
        if (this.sns.like[i].username == this.wxStrId) {
          this.$weui.topTips('已点过赞')
          return
        }
      }
    }
    try {
      await api.doWxTask({
        wechat_id: this.wxId,
        associated_task: this.sns.task_id,
        src_wxid: this.wxStrId,
        task: {
          task_type: 1,
          task_sns: {
            task_type: 4,
            like: {
              task_id: this.sns.task_id,
              sns_id_str: this.sns.sns_id_str
            }
          }
        }
      });
      if (!this.sns.like) this.sns.like = []
      this.sns.like.push({
        username: this.wxStrId,
        nickname: this.deviceInfo.name,
        create_at: parseInt(new Date().getTime() / 1000 + '')
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 评论
   */
  private doComment() {
    this.isShow = true
    this.inputPlaceholder = ''
    const refs: any = this.$refs.commentInput
    if (refs) {
      refs.onFocus()
    }
  }

  /**
   * @func 关闭评论输入框
   */
  private hideInput() {
    this.isShow = false
  }

  /**
   * @func 提交回复
   */
  private async sendReplyComment(comment: string) {
    try {
      await api.doWxTask({
        wechat_id: this.sns.wechat_id,
        associated_task: this.sns.task_id,
        src_wxid: this.wxStrId,
        task: {
          task_type: 1,
          task_sns: {
            task_type: 3,
            comment: {
              task_id: this.sns.task_id,
              comment: [comment],
              ref_comment_svr_id: this.replyConent.comment_svr_id,
              ref_wxid: this.replyConent.wxid,
              ref_nickname: this.replyConent.nickname,
              sns_id_str: this.sns.sns_id_str
            }
          }
        }
      })
      this.hideInput()
      if (!this.sns.comment) {
        this.sns.comment = []
      }
      this.sns.comment.push({
        nickname: this.deviceInfo.name,
        username: this.wxStrId,
        comment: comment,
        commenteeNickname: this.replyConent.nickname,
        commenteeUsername: this.replyConent.wxid
      })
      const refs: any = this.$refs.commentInput
      if (refs) {
        refs.message = ''
      }
    } catch (err) {
      console.log(err)
    }
  }
}
