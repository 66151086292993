













import {
  WechatModule
} from "@/store/modules/wechat";
import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'moment-item-img',
  components: {}
})
export default class MomentItemImg extends Vue {
  @Prop({}) private data!: any
  @Prop({}) private snsId!: string
  private imgLen = 0

  private get wxStrId(): string {
    return WechatModule.wxStrId
  }

  mounted() {
    this.imgLen = this.data.length
  }
}
