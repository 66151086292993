



















import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";
import {
  emojiMap
} from '@/utils/emoji-map'

@Component({
  name: 'bottom-input',
})
export default class BottomInput extends Vue {
  @Prop({
    default: '评论'
  }) private tips!: string;
  private isShowEmoji = false
  private emojis: any = emojiMap
  private activeIndex = ''
  private startPos: number | undefined = 0;
  private endPos: number | undefined = 0;
  private message = ''

  /**
   * @func 输入框聚焦
   */
  public onFocus() {
    this.isShowEmoji = false;
    const refs: any = this.$refs.inputBox
    this.$nextTick(() => {
      refs.focus()
    })
  }

  /**
   * 关闭显示
   */
  private onClose() {
    this.message = ''
    if (this.isShowEmoji) {
      this.isShowEmoji = false
      setTimeout(() => {
        this.$emit('blur')
      }, 500)
    } else {
      this.$emit('blur')
    }
  }

  /**
   * @func 展示emoji
   */
  private showEmoji() {
    this.isShowEmoji = !this.isShowEmoji
    if (!this.isShowEmoji) {
      this.onFocus()
    }
  }

  /**
   * @func 点击表情包
   */
  private selectEmoji(data: any) {
    this.activeIndex = data.tips
    this.append(data.code)
    setTimeout(() => {
      this.activeIndex = ''
    }, 500)
  }

  /**
   * @func 点击emoji
   */
  private append(emoji: any) {
    // if (this.startPos === undefined || this.endPos === undefined) return;
    // const refs: any = this.$refs.focusTextarea
    // if (refs) {
    //   refs.focus()
    // }
    // this.message = this.message.substring(0, this.startPos) + emoji + this.message.substring(this
    //   .endPos);
    // this.startPos += (emoji.length);
    // this.endPos += (emoji.length);
    this.message += emoji
  }

  /**
   * @func 监听点击输入框
   */
  private clickInput() {
    const elInput: any = document.getElementById('emoji-input');
    this.startPos = elInput.selectionStart;
    this.endPos = elInput.selectionEnd;
  }

  /**
   * @func 监听输入框
   */
  private handleInputText(text: any) {
    this.startPos = text.currentTarget.value.length;
    this.endPos = text.currentTarget.value.length;
  }

  /**
   * @func 提交按钮
   */
  private toSubmit() {
    if (!this.message) return
    this.$emit('submit', this.message)
  }
}
