








import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'moment-item-link',
})
export default class MomentItemLink extends Vue {
  @Prop({}) private data!: any


}
